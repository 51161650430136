<div class="exception-body">
  <div class="exception-topbar">
    <a class="logo" href="#">
      <img alt="california-layout" src="../../../assets/layout/images/logo.png"/>
    </a>
  </div>
  <div class="exception-panel">
    <h1 class="exception-summary">{{'accessDenied' | translate}}</h1>
    <p class="exception-detail">
      {{'youDoNotHaveTheNecessaryPermissons' | translate}}.<br>
      {{'searchAgainOrUseTheGoToDashboardButtonBelow' | translate}}.
    </p>
    <div>
      <button [routerLink]="['/']" label="Go To Dashboard" pButton type="button"></button>
    </div>
  </div>
</div>
