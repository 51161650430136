import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { IMe } from '@bs/models';
import { AuthService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppMainComponent } from '../../pages/main/app.main.component';

@Component({
  selector: 'app-inline-profile',
  templateUrl: './app.profile.component.html',
  animations: [
    trigger('menu', [
      state('hiddenAnimated', style({
        height: 0
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*'
      })),
      state('hidden', style({
        height: 0
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppProfileComponent implements OnDestroy {
  me: IMe;
  subs = new Subscription();

  constructor(private authService: AuthService, private messageService: MessageService,
              private translate: TranslateService, public app: AppMainComponent) {
    this.subs.add(authService.accountLogged$.subscribe({
      next: me => this.me = me
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onProfileClick(event) {
    this.app.usermenuClick = true;
    this.app.usermenuActive = !this.app.usermenuActive;
    event.preventDefault();
  }

  logout() {
    this.messageService.add({
      severity: 'info',
      summary: this.translate.instant('logoutSummary'),
      detail: this.translate.instant('logoutDetail')
    });
    this.authService.logout();
  }
}
