import { Component } from '@angular/core';
import { AppSettings } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { environment } from '@winchester/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
  settings: AppSettings;

  constructor(appSettingsService: AppSettingsService) {
    appSettingsService.appSettings$.subscribe({
      next: settings => {
        this.settings = settings.settings;
      }
    });
  }

  vrs = environment.appVersion;

}
