<main (click)="onLayoutClick()"
  [class.layout-wrapper-overlay-sidebar]="layoutMode==='overlay'"
  [class.layout-wrapper-slim-sidebar]="layoutMode==='slim'"
  [class.layout-wrapper-horizontal-sidebar]="layoutMode==='horizontal'"
  [class.layout-wrapper-overlay-sidebar-active]="overlayMenuActive"
  [class.layout-wrapper-sidebar-inactive]="staticMenuDesktopInactive"
  [class.layout-wrapper-sidebar-mobile-active]="staticMenuMobileActive"
  [class.p-ripple-disabled]="!ripple"
  [class.p-input-filled]="inputStyle ==='filled'"
  class="layout-wrapper">
  <app-topbar></app-topbar>

  <div (click)="onMenuClick()" [ngClass]="{'layout-sidebar-dark': menuMode !== 'light',
         'layout-sidebar-gradient':  menuMode === 'gradient'}"
    class="layout-sidebar">
    <div class="sidebar-scroll-content">
      @if (profileMode ==='inline' && layoutMode !== 'horizontal') {
        <app-inline-profile></app-inline-profile>
      }
      <menu></menu>
    </div>
  </div>

  <!--<app-rightpanel></app-rightpanel>-->

  <div class="layout-main">
    <breadcrumb class="hidden md:block"></breadcrumb>

    <div class="layout-main-content">
      <router-outlet (activate)="setMobileActive()"></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>

  <!--<app-config></app-config>-->
</main>
