import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormInterface, Tfa } from '@bs/models';
import { AuthService } from '@bs/services';
import { FormBuilderComponent, loginForm } from '@krusty/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@winchester/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html'
})
export class AppLoginComponent {

  vrs: string;
  form: FormGroup;

  isLoading: boolean;

  constructor(private authService: AuthService, private router: Router, private translate: TranslateService,
              private fb: FormBuilder,) {
    this.vrs = environment.appVersion;
    this.form = fb.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      username: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(20)])]
    });
  }

  submit(value) {
    this.isLoading = true;
    this.authService.login(value).subscribe({
      next: response => {
        if (response.token) {
          this.router.navigate([this.translate.currentLang, 'me', 'profile']);
        } else if (response.tfa) {
          this.form.addControl('tfa', this.fb.group({
            otp: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6)])],
            id: [response.tfa.id]
          }))
        }
      },
      error: error => {
        switch (error.status.code) {
          case 500:
            this.form.setErrors({message: error.status.text});
            break;
          default:
            this.form.setErrors({message: error.message});
        }
      }
    }).add(() => this.isLoading = false);
  }

}
