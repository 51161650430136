<div class="exception-body">
  <div class="exception-topbar">
    <a class="logo" href="#">
      <img alt="california-layout" src="../../../assets/layout/images/logo.png"/>
    </a>
  </div>

  <img alt="california-layout" class="exception-image" src="../../../assets/layout/images/image-404.png"/>

  <div class="exception-panel">
    <img alt="california-layout" class="exception-icon" src="../../../assets/layout/images/icon-404.png"/>
    <h1 class="exception-summary">{{'pageNotFound' | translate}}</h1>
    <p class="exception-detail">
      {{'thePageYouAreLookingIsnNotAvailable' | translate}}.<br>
      {{'tryToSearchAgainOrUseTheGoToDashboardButtonBelow' | translate}}.
    </p>
    <div>
      <button [routerLink]="['/']" label="Go To Dashboard" pButton type="button"></button>
    </div>
  </div>
</div>
