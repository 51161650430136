<div class="route-bar">
  <div class="route-bar-breadcrumb">
    <p-breadcrumb class="hidden md:block" [model]="items" [home]="home"></p-breadcrumb>
  </div>
  <ul class="route-bar-menu">
    <li>
      <bookmaker-selector [isDisabled]="true"></bookmaker-selector>
    </li>
    <li>
      <timezone-selector></timezone-selector>
    </li>
    <li>
      <oddsformat-selector></oddsformat-selector>
    </li>
    <li>
      <displaycurrency-selector></displaycurrency-selector>
    </li>
    <!--    <li class="search-item">-->
    <!--      <i class="pi pi-search"></i>-->
    <!--      <input (keyup.enter)="search()" [placeholder]="'search' | translate" type="text"/>-->
    <!--    </li>-->
    <li>
      <a
        [routerLink]="'me/preferences'" [pTooltip]="'preferences' | translate" tooltipPosition="bottom">
        <i class="pi pi-cog"></i>
      </a>
    </li>
    <!--    <li>-->
    <!--      <a [routerLink]="'accounts/messages'" [pTooltip]="'messages' | translate" tooltipPosition="bottom">-->
    <!--        <i class="pi pi-envelope"></i>-->
    <!--      </a>-->
    <!--    </li>-->
    <li>
      <a (click)="logout()" [pTooltip]="'logout' | translate" tooltipPosition="bottom">
        <i class="pi pi-sign-out"></i>
      </a>
    </li>
  </ul>
</div>
