<div class="login-body">
  <div class="login-topbar">
    <a class="logo">
      <img alt="logo" src="assets/layout/images/logo.png"/>
    </a>
  </div>
  <div class="login-panel p-fluid">
    <div class="grid">
      <div class="col-12"><span>{{'somethingWasWrong' | translate}}.</span></div>
      <div class="col-12">
        <button [routerLink]="['/']" label="Go To Dashboard" pButton type="button"></button>
      </div>
    </div>
  </div>
</div>
