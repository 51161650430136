import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [MessageService, ConfirmationService],
})
export class AppComponent {
  constructor(appSetting: AppSettingsService,
              private messageService: MessageService, translate: TranslateService, router: Router) {
    // authService.isLogged();
    appSetting.globalErrors$.subscribe({
      next: errors => {
        // this.messageService.add({severity: 'error', summary: 'Warning', detail: `${errors.key}:${errors.value}`});
        router.navigate([`${translate.currentLang}/error`]);
      }
    })
  }

}
