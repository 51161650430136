<div class="layout-footer">
  <div class="grid align-items-center">
    <div class="col-12 md:col-6">
      <p [translate]="'footerTitle'"></p>
      <p>{{vrs}}</p>
      <p>{{settings.xDeviceId}}</p>
    </div>
    <div class="col-12 md:col-6 text-right">
    </div>
  </div>
</div>
