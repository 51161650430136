import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppSettings, IMenuItem, WinchesterMenu } from '@bs/models';
import { AppSettingsService, CmsService } from '@bs/services';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppMainComponent } from '../../pages/main/app.main.component';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit, OnDestroy {

  model: IMenuItem[];
  settings: AppSettings;
  subs = new Subscription();

  constructor(public app: AppMainComponent, private cmsService: CmsService,
              public appSettingsService: AppSettingsService,
  ) {
    this.subs.add(this.appSettingsService.appSettings$.subscribe({
      next: data => this.settings = data.settings
    }));

  }

  ngOnInit() {
    this.subs.add(this.cmsService.getMenu(WinchesterMenu.Main).subscribe(
      {
        next: value => {
          //this.model = value[0].items
          this.model = value.items
        }
      }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  toggle(item: MenuItem) {
    item.expanded = !item.expanded;
  }
}
