import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { BookmakersResolver, CurrenciesResolver, PrivateAreaGuard, TranslateGuard, UserLoggedGuard, UserResolver } from '@bs/services';
import { environment } from '@winchester/environments/environment';
import { AppAccessdeniedComponent } from './pages/error/app.accessdenied.component';
import { AppErrorComponent } from './pages/error/app.error.component';
import { AppNotfoundComponent } from './pages/error/app.notfound.component';
import { AppLoginComponent } from './pages/login/app.login.component';
import { AppMainComponent } from './pages/main/app.main.component';

const optionalRoutes: Routes = [];

if (environment.api.reports) {
  optionalRoutes.push({
    path: 'reports',
    resolve: {me: UserResolver},
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule)
  })
}

if (environment.api.accounts) {
  optionalRoutes.push({
      path: 'accounts',
      resolve: {me: UserResolver},
      loadChildren: () => import('./pages/accounts/accounts.module').then(m => m.AccountsModule)
    }, {
      path: 'wallets',
      canActivate: [PrivateAreaGuard],
      resolve: {me: UserResolver},
      loadChildren: () => import('./pages/wallets/wallets.module').then(m => m.WalletsModule)
    },
  )
}

if (environment.api.sportsbook) {
  optionalRoutes.push({
      path: 'sportsbook',
      resolve: {me: UserResolver},
      loadChildren: () => import('./pages/sportsbook/sportsbook.module').then(m => m.SportsbookModule)
    },
  )
}

if (environment.api.games) {
  optionalRoutes.push({
      path: 'games',
      resolve: {me: UserResolver},
      loadChildren: () => import('./pages/games/games.module').then(m => m.GamesModule)
    }
  )
}

if (environment.api.cms) {
  optionalRoutes.push({
      path: 'cms',
      resolve: {me: UserResolver},
      loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule)
    },
  )
}

if (environment.api.promo) {
  optionalRoutes.push({
      path: 'promo',
      resolve: {me: UserResolver},
      loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsModule)
    },
  )
}

if (environment.api.promo) {
  optionalRoutes.push({
      path: 'regulators',
      resolve: {me: UserResolver},
      loadChildren: () => import('./pages/regulators/regulators.module').then(m => m.RegulatorsModule)
    },
  )
}

const routes: Array<Route> = [
  {
    path: '',
    canActivate: [TranslateGuard],
    children: [],
    pathMatch: 'full'
  },
  {
    path: ':lang',
    resolve: {currencies: CurrenciesResolver},
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {path: 'error', component: AppErrorComponent},
      {path: 'auto-logout', component: AppAccessdeniedComponent},
      {path: 'not-authorized', component: AppAccessdeniedComponent},
      {path: 'not-found', component: AppNotfoundComponent},
      {
        path: 'login',
        component: AppLoginComponent,
        canActivate: [UserLoggedGuard]
      },
      {
        path: '',
        component: AppMainComponent,
        resolve: {bookmakers: BookmakersResolver},
        children: [
          {
            path: 'me',
            canActivate: [PrivateAreaGuard],
            resolve: {me: UserResolver},
            loadChildren: () => import('./pages/me/me.module').then(m => m.MeModule)
          },
          ...optionalRoutes,
        ]
      },
    ]
  },
  {path: '**', redirectTo: '../notfound'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      //relativeLinkResolution: 'legacy',
      onSameUrlNavigation: 'reload',
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
