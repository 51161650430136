<div class="layout-topbar flex align-items-center justify-content-between">
  <a class="logo mx-3" [routerLink]="['/']">
    <img alt="california-logo" src="../../../assets/layout/images/logo.png"/>
  </a>
  <a (click)="app.onMenuButtonClick($event)" href="#" id="menu-button">
    <i class="pi pi-align-left"></i>
  </a>
  <div class="topbar-preference lg:hidden">
    <a [routerLink]="'me/preferences'" routerLinkActive="active" class="flex align-items-center justify-content-center">
      <i class="pi pi-cog"></i>
    </a>
  </div>

</div>
