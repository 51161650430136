<!--<pre>{{me.permissions | json}}</pre>-->
<div class="user-profile">
  <a (click)="onProfileClick($event)" href="#" id="sidebar-profile-button">
    <img alt="california-layout" src="assets/layout/images/avatar.png"/>
    <span class="sidebar-profile-name">{{me.person?.firstName}} {{me.person?.lastName}}</span>
    <span class="sidebar-profile-role">{{me.typeId | formValues: { values: 'accountType' } | lowercase | translate}}</span>
  </a>

  <ul [@menu]="app.isSlim() ? (app.usermenuActive ? 'visible' : 'hidden') : (app.usermenuActive ? 'visibleAnimated' : 'hiddenAnimated')" [ngClass]="{'usermenu-active':app.usermenuActive}" class="usermenu">
    <li routerLinkActive="menuitem-active">
      <a [routerLink]="['me/profile']">
        <i class="pi pi-fw pi-user"></i>
        <span class="topbar-item-name" [translate]="'profile'"></span>
      </a>
    </li>
    <li routerLinkActive="menuitem-active">
      <a [routerLink]="['me/security']">
        <i class="pi pi-fw pi-key"></i>
        <span class="topbar-item-name" [translate]="'security'"></span>
      </a>
    </li>
    <li routerLinkActive="menuitem-active">
      <a [routerLink]="['me/activities']">
        <i class="pi pi-fw pi-list"></i>
        <span [translate]="'activities'" class="topbar-item-name"></span>
      </a>
    </li>
    <li routerLinkActive="menuitem-active">
      <a [routerLink]="['me/sessions']">
        <i class="pi pi-fw pi-users"></i>
        <span [translate]="'mySessions'" class="topbar-item-name"></span>
      </a>
    </li>
    <li routerLinkActive="menuitem-active">
      <a [routerLink]="['me/preferences']">
        <i class="pi pi-fw pi-cog"></i>
        <span class="topbar-item-name" [translate]="'myPreferences'"></span>
      </a>
    </li>
    <li routerLinkActive="menuitem-active">
      <button (click)="logout()" class="p-link" type="button">
        <i class="pi pi-fw pi-sign-out"></i>
        <span class="topbar-item-name" [translate]="'logout'"></span>
      </button>
    </li>
  </ul>
</div>
