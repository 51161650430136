import { Component, OnInit } from '@angular/core';
// import { MenuService } from './app.menu.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html'
})
export class AppMainComponent implements OnInit {

  layoutMode = 'static';

  megaMenuMode = 'gradient';

  menuMode = 'light';

  profileMode = 'inline';

  topbarMenuActive: boolean;

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  menuClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: any;

  menuHoverActive: boolean;

  rightPanelActive: boolean;

  rightPanelClick: boolean;

  megaMenuActive: boolean;

  megaMenuClick: boolean;

  usermenuActive: boolean;

  usermenuClick: boolean;

  activeProfileItem: any;

  inputStyle = 'outlined';

  ripple: boolean;

  configActive: boolean;

  configClick: boolean;

  constructor(private primengConfig: PrimeNGConfig) {
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    if (!this.megaMenuClick) {
      this.megaMenuActive = false;
    }

    if (!this.usermenuClick && this.isSlim()) {
      this.usermenuActive = false;
      this.activeProfileItem = null;
    }

    if (!this.menuClick) {
      /*if (this.isHorizontal() || this.isSlim()) {
        this.menuService.reset();
      }*/

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    if (this.configActive && !this.configClick) {
      this.configActive = false;
    }

    this.configClick = false;
    this.topbarItemClick = false;
    this.menuClick = false;
    this.rightPanelClick = false;
    this.megaMenuClick = false;
    this.usermenuClick = false;
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.topbarMenuActive = false;

    if (this.layoutMode === 'overlay') {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
    }

    event.preventDefault();
  }

  onMenuClick() {
    this.menuClick = true;
  }

  /*onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }*/

  /*onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }*/

 /* onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;
    event.preventDefault();
  }*/

  onRightPanelClick() {
    this.rightPanelClick = true;
  }

  /*onMegaMenuButtonClick(event) {
    this.megaMenuClick = true;
    this.megaMenuActive = !this.megaMenuActive;
    event.preventDefault();
  }*/

  /*onMegaMenuClick() {
    this.megaMenuClick = true;
  }*/

  hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

 /* onRippleChange(event) {
    this.ripple = event.checked;
  }*/

  /*onConfigClick() {
    this.configClick = true;
  }*/

  isDesktop(): boolean {
    return window.innerWidth > 1024;
  }

 /* isHorizontal() {
    return this.layoutMode === 'horizontal';
  }*/

  isSlim() {
    return this.layoutMode === 'slim';
  }

  /*isOverlay() {
    return this.layoutMode === 'overlay';
  }*/

  setMobileActive() {
    setTimeout(() => {
      this.staticMenuMobileActive = this.isDesktop()
    })
  }
}
