<div class="login-body">
  <div class="login-topbar flex align-items-center px-3">
    <a class="logo">
      <img alt="logo" src="assets/layout/images/logo.png"/>
    </a>
  </div>
  <div class="login-panel h-screen pb-8">
    <div class="h-full">
      <div class="flex justify-content-center lg:align-items-center h-full pt-7 lg:pt-0">
        <div class="card-container align-self-start lg:align-self-center py-6 px-4 shadow-2 border-round w-full mx-3">
          <div class="lg:px-8 lg:mx-4">
            <div class="mt-3 mb-2">
              <label class="block mb-3">{{ 'selectLanguage' | translate }}</label>
              <language-selector class="w-full"></language-selector>
            </div>
            <div class="px-2 pt-2">
              <form (ngSubmit)="submit(form.value)" [formGroup]="form" autocomplete="off"
                class="p-fluid flex-column grid">
                <field class="grid col flex-column p-field" formControlName="username" [input]="{name:'username', type:'text', label:'username'}">
                  <span class="p-inputgroup-addon pre">
                    <i class="pi pi-user"></i>
                  </span>
                </field>
                <field class="grid col flex-column p-field" formControlName="password" [input]="{name:'password', type:'password', label:'password'}">
                  <span class="p-inputgroup-addon pre">
                    <i class="pi pi-lock"></i>
                  </span>
                </field>
                @if (form.get('tfa'); as tfaCtrl) {
                  <div formGroupName="tfa">
                    <field class="grid col flex-column p-field" formControlName="otp" [input]="{name:'otp', type:'text', label:'otp'}">
                      <span class="p-inputgroup-addon pre"><i class="pi pi-lock"></i></span>
                    </field>
                  </div>
                }
                <div class="button-container mb-5">
                  <button [disabled]="isLoading || form.invalid" [label]="'signIn' | translate" pButton type="submit">
                    @if (isLoading) {
                      <p-progressSpinner [style]="{width: '25px', height: '25px'}" animationDuration=".5s"
                      strokeWidth="8" styleClass="custom-spinner"></p-progressSpinner>
                    }
                  </button>
                </div>
                <form-errors [form]="form"></form-errors>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">{{ vrs }}</div>
    </div>
  </div>
</div>

