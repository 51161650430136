import { Component } from '@angular/core';
import { AuthService, CatalogService } from '@bs/services';
import { BreadcrumbService } from '@krusty/common';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';

@Component({
  selector: 'breadcrumb',
  templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent {

  items: MenuItem[];
  home: MenuItem;

  constructor(private authService: AuthService, breadcrumbService: BreadcrumbService,
              private messageService: MessageService,
              private catalogService: CatalogService,
              private translate: TranslateService) {

    this.items = [
      {label: 'Item'}
    ];
    this.home = {icon: 'pi pi-home', routerLink: '/profile'};

  }

  logout() {
    this.messageService.add({
      severity: 'info',
      summary: this.translate.instant('logoutSummary'),
      detail: this.translate.instant('logoutDetail')
    });
    this.authService.logout();
  }

  search() {
    this.messageService.add({
      severity: 'error',
      summary: this.translate.instant('workInProgress'),
      detail: this.translate.instant('checkLater')
    });
  }
}
