import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeSq from '@angular/common/locales/sq';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfigModule, TranslationModule } from '@bs/common';

/* SHARED MODULES */
import { KrustyCommonModule, PrimeModule } from '@krusty/common';

// Application services
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';

// Application Components
import { AppComponent } from './app.component';
import { AppBreadcrumbComponent } from './components/breadcrumb/app.breadcrumb.component';
import { AppProfileComponent } from './components/profile/app.profile.component';
import { AppTopBarComponent } from './components/topbar/app.topbar.component';
import { AppAccessdeniedComponent } from './pages/error/app.accessdenied.component';
import { AppErrorComponent } from './pages/error/app.error.component';
import { AppNotfoundComponent } from './pages/error/app.notfound.component';
import { AppLoginComponent } from './pages/login/app.login.component';
import { AppMainComponent } from './pages/main/app.main.component';

import { AppFooterComponent } from './views/footer/app.footer.component';
import { MenuComponent } from './views/menu/menu.component';

registerLocaleData(localeIt, 'it');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeSq, 'pt-BR');
registerLocaleData(localeDe, 'de');

@NgModule({
  imports: [
    // NATIVE
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // SHARED
    KrustyCommonModule.forRoot(environment),
    PrimeModule.forRoot(),
    TranslationModule.forRoot(environment),
    ConfigModule.forRoot(environment)
  ],
  declarations: [
    AppComponent,
    AppMainComponent,
    MenuComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppProfileComponent,
    AppBreadcrumbComponent,
    AppLoginComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
