<ng-template #linkTpl let-item>
  <a [routerLink]="'/' + settings.languageCode + item.routerLink" routerLinkActive="active">
    <i class="pi pi-{{item.icon}}"></i>
    <span>{{item.label}}</span>
    @if (item.badge) {
      <span [ngClass]="item.badgeStyleClass" class="menuitem-badge">{{item.badge}}</span>
    }
    @if (item.items) {
      <i class="pi pi-fw pi-angle-down layout-menuitem-toggler"></i>
    }
  </a>
</ng-template>

<ng-template #noLinkTpl let-item>
  <button (click)="toggle(item)" [class.active]="item.expanded" [routerLink]="item.url" class="p-link" routerLinkActive="active" type="button">
    <i class="pi pi-{{item.icon}}"></i>
    <span>{{item.label}}</span>
    @if (item.badge) {
      <span [ngClass]="item.badgeStyleClass" class="menuitem-badge">{{item.badge}}</span>
    }
    @if (item.items) {
      <i class="pi pi-fw pi-angle-down layout-menuitem-toggler"></i>
    }
  </button>
</ng-template>

<ng-template #itemTpl let-item>
  <!--<div (click)="toggleSelect(item)" [ngClass]="{'has-sub': item.items?.length}">-->
  <ng-template [ngTemplateOutletContext]="{ $implicit: item }" [ngTemplateOutlet]="item.routerLink ? linkTpl:noLinkTpl"></ng-template>
<!--</div>-->
</ng-template>

<ng-template #hasSubTpl let-item let-level="level">
  <ng-template [ngTemplateOutletContext]="{ $implicit: item }" [ngTemplateOutlet]="itemTpl"></ng-template>
  <ul class="layout-menu lev-sub lev-{{level}}">
    <ng-template [ngTemplateOutletContext]="{ $implicit: item.items, level:(level+1) }" [ngTemplateOutlet]="recursiveList"></ng-template>
  </ul>
</ng-template>

<ng-template #recursiveList let-items let-level="level">
  @for (item of items; track item) {
    <li [ngClass]="{leaf: !item.items, expanded: item?.expanded || item?.selected}">
      <ng-template [ngTemplateOutletContext]="{ $implicit: item, level:level }" [ngTemplateOutlet]="item.items?.length ? hasSubTpl:itemTpl"></ng-template>
    </li>
  }
</ng-template>

<ul class="layout-menu">
  <ng-template [ngTemplateOutletContext]="{ $implicit: model, level:1 }" [ngTemplateOutlet]="recursiveList"></ng-template>
</ul>
